import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Layout from '../../components/layout/layout';
import dash from '../../images/hexowatch-home.png';
import api from '../../images/advanced-monitorings/api.png';
import automaticAi from '../../images/advanced-monitorings/automatic-ai.png';
import availability from '../../images/advanced-monitorings/availability.png';
import backlink from '../../images/advanced-monitorings/backlink.png';
import content from '../../images/advanced-monitorings/content.png';
import element from '../../images/advanced-monitorings/element.png';
import keyword from '../../images/advanced-monitorings/keyword.png';
import rss from '../../images/advanced-monitorings/rss.png';
import sitemap from '../../images/advanced-monitorings/sitemap.png';
import sourceCode from '../../images/advanced-monitorings/source-code.png';
import technology from '../../images/advanced-monitorings/technology.png';
import visual from '../../images/advanced-monitorings/visual.png';
import whois from '../../images/advanced-monitorings/whois.png';

import './styles.scss';

const About = () => {
  const advancedMOnitorings = [
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Detect <span className="blue_color">visual changes</span> on any page
        </h2>
      ),
      text:
        'The visual monitor is a powerful tool, enabling you to capture and archive screenshots and receive instant notifications when visual changes are detected.',
      img: visual,
      alt: 'Visual monitor for screenshot capture and change notifications',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Spot <span className="blue_color">content changes</span>
        </h2>
      ),
      text:
        'Do you need to focus on content changes only? With Hexowatch, you can ignore any visual or source code changes and only receive an alert when visible text is updated. This is ideal for monitoring landing page copy, terms, and conditions, or affiliate disclosures on any page.',
      img: content,
      alt: 'Hexowatch content change alerts for text updates only',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Track <span className="blue_color">prices</span> or product <span className="blue_color">availability</span>
        </h2>
      ),
      text:
        'The HTML element monitor empowers you to track one or more fields on a page, such as prices or product availability. This is like having a personal assistant who keeps an eye on specific information for you and alerts you when something changes.',
      img: element,
      alt: 'HTML element monitor for tracking specific fields on a page',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          <span className="blue_color">Get alerts</span> when pages are published
        </h2>
      ),
      text:
        'Want to know when a competitor launches a new product or when a new article is published? Our sitemap monitor enables you to get sitewide website monitoring alerting you when new pages are updated or published from an XML sitemap.',
      img: sitemap,
      alt: 'Sitemap monitor for new page and article alerts',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Scan for <span className="blue_color">specific keywords</span> on a page
        </h2>
      ),
      text:
        'The keyword monitor lets you know if specific keywords are found or removed from a page, this is ideal for brand monitoring or getting a heads-up when an offer is launched.',
      img: keyword,
      alt: 'Keyword monitor for brand or offer updates',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Detect changes in <span className="blue_color">tech stack</span>
        </h2>
      ),
      text:
        'Did your Google Analytics tag go missing? Did your prospect start using a new visitor chat platform? Stay ahead of your market and get notified as soon as any changes in tech stack are detected.',
      img: technology,
      alt: 'Tech stack change alerts for Google Analytics and chat platforms',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Discover<span className="blue_color"> source code changes</span>
        </h2>
      ),
      text:
        'Get alerts when the HTML code of any web page changes, ideal for highlighting any potential malicious code changes.',
      img: sourceCode,
      alt: 'HTML code change alerts for potential malicious changes',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Get Alerts when<span className="blue_color"> WHOIS or DNS changes occur</span>
        </h2>
      ),
      text:
        'The WHOIS monitor alerts you when any changes are detected to the admin records of domain names, ideal for changes of ownership or keeping an eye on expiring domains.',
      img: whois,
      alt: 'WHOIS monitor for domain admin record changes',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Monitor for downtime on<span className="blue_color"> any URL</span>
        </h2>
      ),
      text:
        'The availability monitor checks any URL for downtime alerting you when a page is unavailable and when it comes back.',
      img: availability,
      alt: 'URL availability monitor for downtime alerts',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Monitor <span className="blue_color">any API</span> for changes
        </h2>
      ),
      text: 'Be the first to know if your API endpoints experience any anomalies or downtime.',
      img: api,
      alt: 'API endpoint anomaly and downtime alerts',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Keep an eye on your <span className="blue_color">SEO backlink partners</span>
        </h2>
      ),
      text: 'Monitor your acquired backlinks and get notified when links back to your website are removed or modified.',
      img: backlink,
      alt: 'Backlink monitor for link removal or modification alerts',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Check <span className="blue_color">RSS feeds</span> for changes
        </h2>
      ),
      text:
        'Monitor any RSS feed for changes or updates, this is ideal to get notifications when new articles or products are published.',
      img: rss,
      alt: 'RSS feed monitor for new articles or products',
    },
    {
      title: (
        <h2 className="f_size_30 f_700 l_height40 t_color2 text-center text-lg-left">
          Get notified of <span className="blue_color">any change</span> on auto-pilot
        </h2>
      ),
      text:
        'The AI monitor provides an auto-pilot mode that alerts you when any visual, content, source code, or technology changes are detected.',
      img: automaticAi,
      alt: 'AI monitor for auto-pilot alerts on visual, content, code, and tech changes',
    },
  ];

  return (
    <Layout noBg>
      <section id="seo_home_area" className="seo_home_area with-bg">
        <div className="container_home">
          <div className="row_home pb-3">
            <Row className="m-auto justify-content-center">
              <Col lg={6} className="my-auto">
                <h1 className="f_size_38 f_700 l_height50 t_color2 text-center text-lg-left text-center text-lg-left">
                  <span className="w_color">
                    Advanced Monitoring: <br className="d-none d-lg-flex" />
                    Cut The Noise & Never Miss <br className="d-none d-lg-flex" />
                    An{' '}
                  </span>
                  Important Change
                </h1>
                <div className="w_color f_size_16 text-center text-lg-left m-auto m-lg-0" style={{maxWidth: '400px'}}>
                  Hexowatch provides 13 different monitoring options to help you stay informed when specific changes
                  occur on any website.
                </div>
                <div className="d-flex d-lg-none w-100">
                  <img src={dash} alt="13 different monitoring options" className="m-auto" style={{maxWidth: '100%'}} />
                </div>
              </Col>
              <Col lg={6} className="d-none d-lg-flex">
                <img
                  src={dash}
                  alt="13 different monitoring options"
                  style={{
                    maxWidth: '100%',
                    margin: 'auto -20px auto 0',
                    zIndex: 10,
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section className="pt-5 container">
        {advancedMOnitorings.map((item: any, index: number) => (
          <Row className={`${index % 2 === 0 ? '' : 'row-reverse'} my-4`} key={item.name}>
            <Col lg={6} className="my-auto p-4">
              {item.title}
              <p className="f_size_18 f_400 t_color4 text-center text-lg-left">{item.text}</p>
            </Col>
            <Col lg={6} className="m-auto p-4">
              <img src={item.img} style={{maxWidth: '100%'}} className="m-auto" alt={item.alt} />
            </Col>
          </Row>
        ))}
      </section>
    </Layout>
  );
};

export default About;
